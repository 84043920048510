.App, #root{
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
}
@font-face {
    font-family: 'Gotham-Book';
    src: url('../src/assets/fonts/GothamBook.ttf') format('truetype');
    /* Additional font properties like font-weight and font-style can be specified here */
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../src/assets/fonts/GothamBold.ttf') format('truetype');
    /* Additional font properties like font-weight and font-style can be specified here */
}

@font-face {
    font-family: 'Gotham-Light';
    src: url('../src/assets/fonts/GothamLight.ttf') format('truetype');
    /* Additional font properties like font-weight and font-style can be specified here */
}

@font-face {
    font-family: 'Halveltica';
    src: url('../src/assets/fonts/HelveticaNeueRegular.ttf') format('truetype');
    /* Additional font properties like font-weight and font-style can be specified here */
}






.about-wrapper{
    margin-top: 0px;
    .about-container{
        width: 80%;
        margin: 0 auto;
        display: flex;
        .about-content{
            color: white;
            text-align: left;
            font-size: 12px;
            font: normal normal lighter Gotham-Light;
        }
        .spacer-h{
            flex: 1;
        }
    }
}
body, html {
  margin: 0!important;
  padding: 0!important;
  font-family: 'Gotham-Book', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}

@media only screen and (max-width: 700px) {
  body{
  /* background-color: black;  */

  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 700px) {
  h2{
    font-size: 16px; 
  }
}
h2{
  text-align: left;
  font: normal normal bold 60px/20px Gotham-bold;
  letter-spacing: 0px;
  opacity: 1;
  
}
.m_50{
  margin-top: 100px;
}

.bolder{
  font-weight: 600;
}
.f_1{
  flex: 1;
}
.f_2{
  flex: 2;
}

.f_3{
  flex: 3;
}

.f_4{
  flex: 4;
}
.white{
  color: white;
}
.yellow{
  color: #F7941C;
}

.spacer{
  width: 100px;
}
.event-wrapper{
    margin-top: 0px;
    height: 300px;
    background-color: #000;
    padding-bottom: 20px;
    @media only screen and (max-width: 700px) {
       height: 560px;
    }
    .event-container{
        color: white;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
        width: 80%;
        margin: 0 auto;
        .event-content{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media only screen and (max-width: 700px) {
                flex-direction: column;
            }
            a{
                color: unset;
                width: 40%;
                @media only screen and (max-width: 700px) {
                    width: 100%;
                }
                .event{
                    margin-bottom: 20px;
                    background-color: #2E2E2E;
                    border-radius: 20px;
                    cursor: pointer;
                    padding: 20px;
                    .event-holder{
                        background-image: url('../assets/images/event_1.jpg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        padding: 10px 20px;
                        height: 120px;
                        .title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width: 160px;
                            }
                            h2{
                                font-size: 30px;
                                font-weight: bolder;
                                line-height: 30px;
                            }
                        }
                    }
                    .bg_d{
                        background-image: url('../assets/images/event_1.jpg');
                    }
                    .bottom{
                        display: flex;
                        margin-top: 20px;
                        @media only screen and (max-width: 700px) {
                            margin-top: -20px;                            
                         }
                        .date-bottom{
                            background-color: black;
                            border-radius: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50px;
                            height: 60px;
                            padding-left:10px;
                            h2{
                                font-size: 16px;
                                margin-top: 30px;
                            }
                        }
                        .text-bottom{
                            width: 170px;
                            margin-top: -5px;
                            @media only screen and (max-width: 700px) {
                                margin-top: -50px;                                
                             }
                            h2{
                                font-size: 18px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .event_bg{
                width: 50%;
                img{
                    width: 95%;
                }
            }
        }
        h2{
            margin-bottom: 30px;
            font-size: 38px;
            @media only screen and (max-width: 700px) {
                margin-top: 60px;
            }
        }
    }
}

.mt_20{
    margin-top: 20px;
}

.footer-wrapper{
    margin-top: 50px;
    padding-top: 20px;
    .footer-container{
        border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
        width: 80%;
        display: flex;
        padding: 20px 0;
        justify-content: space-between;
        margin: 0 auto;
        span{
            color: #818181;
            font-size: 18px;
            font-weight: 400;
            @media only screen and (max-width: 700px) {
                font-size: 12px;
            }
        }
    }
}
.single-wrapper{
    width: 100%;
    .single-details{
        width: 100%;
        background-image: url('../assets/images/genz.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #000;
        height: 450px;
        @media only screen and (max-width: 700px) {
            background-image: url('../assets/images/genz_m.jpg');
        }
        .single-details-content{
            @media only screen and (max-width: 700px) {
                display: none;
            }
            color: #fff;
            background-color:  rgba($color: #005B90, $alpha: 0.9);
            margin-top: 170px;
            padding: 20px 20px;
            float: right;
            border-radius: 39px;
            // flex: 1;
            h2{
                font-size: 36px;
                margin-top: 10px;
            }
            .detail{
                margin-top: 10px;
                color: #fff!important;
            }
        }
        .single-details-content-mobile{
            display: none;
            @media only screen and (max-width: 700px) {
                display: unset;
            }
            color: #fff;
            background-color:  rgba($color: #005B90, $alpha: 0.9);
            margin-top: 190px;
            padding: 0px 20px;
            float: left;
            border-top-right-radius: 29px;
            width: 70%;
            border-bottom-right-radius: 29px;
            // flex: 1;
            h2{
                font-size: 16px;
                margin-top: 10px;
                font: normal normal normal 18px/20px Gotham-Book;
                .bgg{
                    font-size: 36px;
                    margin-top: 10px;
                    font-weight: bold;
                    font: normal normal bold 32px/70px Gotham-bold;
                }
                .detail{
                    margin-top: 10px;
                    font-weight: 400!important;
                    color: #fff!important;
                }
            }
           
        }
    }
    .single-bg-container{
        background-color: #000;
        padding-top: 30px;
        height: 250px;
        @media only screen and (max-width: 700px) {
            height: 700px;
        }
        .single-container{
            width: 80%;
            margin: 0 auto;
            color: #fff;
            font-size: 36px;
            h2{
                color: #fff;
                font-size: 46px;
            }
            .single-container-row{
                display: flex;
                @media only screen and (max-width: 700px) {
                   flex-direction: column;
                }
                .ticket-holder{
                    border-radius: 12px;
                    background-color: #151515;
                    border-radius: 10px;
                    padding: 15px 20px;
                    flex: 1;
                    @media only screen and (max-width: 700px) {
                        margin-top: 20px;
                    }
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type="number"] {
                        -moz-appearance: textfield;
                        outline: none;
                        padding: 10px 10px;
                        color: #2E2E2E;
                        font-weight: 500;
                        font-size: 16px;
                        background: #D8D8D8 0% 0% no-repeat padding-box;
                        border: 1px solid #A7A7A7;
                        border-radius: 3px;
                        opacity: 1;
                    }
                    .cat-container{
                        display: flex;
                        flex-wrap: wrap;
                        .cat-item{
                            margin-right: 20px;
                            margin-bottom: 20px;
                            display: flex;
                            cursor: pointer;
                            justify-content: center;
                            align-items: center;
                            border-radius: 12px;
                            border: 1px solid #F7941C;                        ;
                            height: 60px;
                            padding: 5px 30px;
                            .cat-content{
                                display: flex;
                                .cat-col{
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 20px;
                                    margin-top: -8px;
                                    span{
                                        font-size: 14px;
                                        line-height: 22px;
                                        font-weight: bolder;
                                    }
                                }
                            }
                        }
                        .cat_active{
                            background-color: #F7941C;
                            .circled{
                                .inner-circled{
                                    display: unset;
                                }
                            }
                        }
                    }
                    .nbr-container{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-self: flex-end;
                        span{
                            font-size: 16px;
                            margin-right: 20px;
                            @media only screen and (max-width: 700px) {
                                font-size: 12px;
                            }
                        }
                        input[type="number"]{
                            width: 40px;
                        }
                        .inc-buttons{
                            margin-left: 20px;
                            margin-top: 5px;
                            display: flex;
                            flex-direction: column;
                            .inc-button{
                                background: #D8D8D8 0% 0% no-repeat padding-box;
                                border: 1px solid #A7A7A7;
                                border-radius: 3px;
                                color: black;
                                font-size: 10px;
                                margin-bottom: 3px;
                                padding: 1px;
                                cursor: default;
                            }
                        }
                    }
                }
                .f_2{
                }
            }
        }
    }
}

.pay-container{
    img{
        width: 80px;
    }
    .pay-input{
        margin-top: 0px;
        @media only screen and (max-width: 700px) {
            padding-top: 30px;
        }
        input{
            @media only screen and (max-width: 700px) {
                margin-top: 20px;
            }   
        }
        span{
            font-size: 20px;
            @media only screen and (max-width: 700px) {
                margin: 20px 0;
                font-size: 14px;
                padding-bottom: 20px;
            }
        }
        button{
            background: #F7941C 0% 0% no-repeat padding-box;
            border-radius: 3px 10px 10px 3px;
            outline: none;
            border: none;
            padding: 10px 20px;
            margin-left: 20px;
            @media only screen and (max-width: 700px) {
                margin-left: 0px;
                margin-top: 20px;
                border-radius: 3px;
            }
            cursor: pointer;
            span{
                color: #fff;
                font-size: 18px;
            }
            .bld{
                font-weight: bold;
                margin-left: 5px;
            }
        }
        input[type="number"] {
            width: 180px;
        }
    }
}

.circled{
    width: 18px;
    height: 18px;
    border-radius: 100%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid white;
    .inner-circled{
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 100%;
        display: none;
    }
}

.bg_msg{
    background-color: darkgreen;
}
.header-wrapper{
    width: 100%;
    margin: 0;
    position: absolute;
    // background: #000000 0% 0% no-repeat padding-box;
    @media only screen and (max-width: 700px) {
       background: #000000 0% 0% no-repeat padding-box;
       position: relative;
       height: 150px;
    }
    .header-container{
        display: flex;
        width: 95%;
        margin: 0 auto; 
        position: relative;
        .logo{
            flex: 1;
            position: absolute;
            background-color: #000000;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            img{
                width: 140px;
                height: 140px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                @media only screen and (max-width: 700px) {
                    height: unset;
                    width: 180px;
                    margin-top: -20px;
                    border-bottom-left-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
            }
        }
        .menu{
            flex: 2;
            display: flex;
            justify-content: end;
            li{
                padding: 10px 20px;
                color: white;
                list-style-type: none;
                text-align: left;
                letter-spacing: 0px;
                opacity: 1;
                &:hover{
                    color: #F6931D;
                    cursor: pointer;
                }
            }
            .active{
                color: #F6931D;
            }
        }
    }
}

a{
    text-decoration: none;
}

.hide{
    opacity: 0;
}
.slider-wrapper{
    width: 100vw;
    height: 450px;
    background-image: url('../assets/images/genz.jpg');
    background-size: 100vw;
    background-repeat: no-repeat;
    @media only screen and (max-width: 700px) {
        background-image: url('../assets/images/genz_m.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100vw;
        height: 370px;
        background-color: #F7941C;
        // padding-top: 550px;
    }
    .slider-container{
        width: 100%;
        position: relative;
        img{
            max-width: 100%;
        }
        .booker{
            position: absolute;
            top: 410px;
            right: 50px;
            @media only screen and (max-width: 700px) {
                top: 350px;
                right: 10px;
            }
            button{
                background: #F7941C 0% 0% no-repeat padding-box;
                border-radius: 5px;
                outline: none;
                border: none;
                padding: 15px 20px;
                @media only screen and (max-width: 700px) {
                    border-radius: 20px;
                }
                margin-left: 20px;
                cursor: pointer;
                span{
                    color: #fff;
                    font-size: 18px;
                }
                .bld{
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }
}